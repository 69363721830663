<template>
  <div
    class="notification"
    v-if="currentUser && currentUser.code_client == null"
  >
    <!-- <v-tooltip bottom color="#311B92"> -->

    <!-- <span>Liste des notifications</span>
    </v-tooltip> -->
    <v-menu
      min-width="400px"
      max-width="400px"
      origin="top right"
      content-class="popover-menu-notification"
    >
      <template v-slot:activator="{ on, attrs }">
        <div size="sm" v-on="on" v-bind="attrs" class="ml-3 btn-notif">
          <font-awesome-icon
            :icon="['far', 'bell']"
            class="cursor-pointer img-h-19"
          />
          <div class="notification-circle">
            {{
              getNotification && getNotification.length > 0
                ? getNotification.length
                : 0
            }}
          </div>
        </div>
      </template>
      <v-card>
        <v-list class="list-notif" dense>
          <v-list-item class="list-item-notif">
            <v-row class="item-centre">
              <v-col cols="8">
                <v-list-item-content class="title-list">
                  <v-list-item-title>Notifications</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col>
                <v-list-item-content class="title-list">
                  <v-list-item-title>
                    <span class="voir-plus">
                      <router-link to="/notifications">{{
                        $i18n.locale === 'fr' ? 'Voir tout' : 'See all'
                      }}</router-link>
                    </span></v-list-item-title
                  >
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-divider class="line-separator-list"></v-divider>
        <!-- <LoadingComponent v-if="getNotificationsLoader" /> -->
        <v-list class="box-dropdown">
          <v-list-item
            v-for="(notif, index) in getNotification"
            :key="'notification' + notif.id + index"
          >
            <v-list-item-content>
              <v-row
                class="block-notif"
                :class="{ 'gris-notif': notif.depuis == null }"
              >
                <v-col cols="2" class="ml-2">
                  <div class="icon-type-notif">
                    <font-awesome-icon
                      :icon="iconEntity(notif.activity_type)"
                    />
                  </div>
                </v-col>
                <v-col cols="7">
                  <div>
                    <router-link
                      class="route-notif"
                      :to="'/projects/' + notif.projet_id + '/activity'"
                    >
                      <div class="content-notif">
                        <div class="content">
                          <div class="titre-notif mb-1">
                            {{ notif.activity_name }}
                          </div>
                          <div>{{ notif.contenu.split(' , ')[0] }} <br /></div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="hour-color mt-1" v-if="notif.time">
                    {{ notif.activity_date_start | timeFromNow(notif) }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <span class="pointe-notif" v-if="notif.depuis == null"></span>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="getNotification && getNotification.length == 0">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="2"> </v-col>
                <v-col cols="8" class="mt-0 mb-3">
                  <div
                    class="empty-export font-text font-sz-15 text-center bold-500"
                  >
                    {{
                      $i18n.locale === 'fr'
                        ? 'Aucune notification'
                        : 'No notifications'
                    }}
                  </div>
                </v-col>
                <v-col cols="2"> </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// import LoadingComponent from '@/views/component/LoadingComponent';
import moment from 'moment'

import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return { menu: false }
  },
  methods: {
    ...mapActions([])
  },
  mounted() {},
  computed: {
    ...mapGetters(['getNotification', 'currentUser']),
    iconEntity() {
      return function(type_activity) {
        if (type_activity === 'Pré visite') {
          return ['fas', 'briefcase']
        } else if (type_activity === 'Appel') {
          return ['fas', 'phone-alt']
        } else if (type_activity === 'Planning') {
          return ['fas', 'truck']
        } else {
          return ['fas', 'hourglass-start']
        }
      }
    }
  },
  filters: {
    timeFromNow: function(value, notif) {
      if (!value) return ''
      var seconds = notif.time.split(':')[2]
      var minutes = notif.time.split(':')[1]
      var hours = notif.time.split(':')[0]
      var result = ''
      var starttime = moment(value, 'YYYY-MM-DD hh:mm:ss')
      var diff = ''
      if (notif.depuis == '1') {
        result = moment(value)
          .add(seconds, 'seconds')
          .add(minutes, 'minutes')
          .add(hours, 'hours')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      } else {
        result = moment(value)
          .subtract(seconds, 'seconds')
          .subtract(hours, 'hours')
          .subtract(minutes, 'minutes')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      }

      return diff
    }
  },
  components: {
    // LoadingComponent
  }
}
</script>

<style lang="scss" scoped>
.popover-menu-notification {
  right: 236px;
  left: unset !important;
  top: 65px !important;
}
.btn-notif {
  padding: 14px;
  margin-top: 17px;
}
.route-notif {
  color: black !important;
  font-family: 'Montserrat' sans-serif;
  font-size: 13px;
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
.router-link-active {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
.content-notif {
  display: flex;
  align-items: center;
}
.block-notif {
  &:hover {
    background-color: #5c2dd31a;
  }
  &.gris-notif {
    background-color: #5c2dd31a;
  }
}

.titre-notif {
  color: #5c2dd3 !important;
  font-family: 'Montserrat' sans-serif;
  font-size: 13px;
  font-weight: 700;
}
.hour-color {
  color: #5c2dd3 !important;
  font-family: 'Montserrat' sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.pointe-notif {
  background: #5c2dd3;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 0px;
  display: block;
  width: 10px;
  height: 10px;
}
.icon-type-notif {
  border: 1px solid;
  padding: 12px 12px;
  border-radius: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #5c2dd3;
}
.icon-notif {
  font-size: 21px;
  margin-top: 13px;
  color: #3c454b;
}
.line-separator-list {
  margin-top: -11px;
}
.voir-plus {
  cursor: pointer;
  color: #5c2dd3 !important;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  display: block;
  &:hover {
    text-decoration: underline;
  }
  a {
    color: #5c2dd3 !important;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
.notification-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5c2dd3;
  font-size: 11px;
  top: -28px;
  right: -10px;
  color: #fff;
  position: relative;
  z-index: 10;
  text-align: center;
}
.box-dropdown {
  overflow-y: auto;
  max-height: 365px;
  .v-list-item {
    padding: 0px 10px !important;
  }
}
.box-dropdown::-webkit-scrollbar {
  width: 10px;
}

.box-dropdown::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.box-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
.box-dropdown::-webkit-scrollbar-thumb {
  background: #5c2dd3;
  border-radius: 10px;
}
</style>
